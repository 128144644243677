
import PlayerFooter from "./PlayerFooter";
import Accordion from '../shared/Accordion';
import SplitPane from 'react-split-pane';
// import './assets/css/bronte_theme2.css';
import { forwardRef, useEffect, useRef } from "react";
import NarrativeContent from "./NarrativeContent";

const PlayerContent = forwardRef((props, ref) => {
    const {iFrameRef, ref1} = ref;
    const iframe = useRef();

    // useEffect(() => {
    //     if (props.data.content[0].type === 'applet') {
    //         let styleTag = document.createElement("style");
    //             // document.head.appendChild(styleTag);
    //             styleTag.textContent = `#playerBody .footer {display:none;}`;
                
    //         // window.addEventListener("message", receiveMessage, false);
    //         // iframe.current.contentWindow.postMessage(styleTag, "*")
    //         console.log(styleTag)
    //         if (iframe.current) {
    //             iframe.current.addEventListener('contentloaded')
    //             // var headID = document.getElementsByTagName("head");         
    //             // console.log(iframe.current.contentWindow.document)
    //             // var y = iframe.current.contentWindow.document;
    //             // y.body.style.backgroundColor = "red";
    //             var buts = iframe.current.contentDocument.body.querySelector("footer")
    //             console.log(buts)
    //             // iframe.current.contentWindow.document.documentElement.head.appendChild(styleTag)
    //         }
    //     }
    // },[props.data])

    const hideFooterInIframe = () => {
        iframe.current.contentWindow.document.body.style.backgroundColor = "red"
    }

    let random = Math.random().toFixed(4);
    
    // console.log(props.totalItems)
    
    return(
        <article className="assignment-content">
            <section className="item-content" id="assignmentContent">
                {props.data.content && props.data.content.map((contentPart, i) => {
                   
                    return(
                        <>
                            {contentPart.type === 'video' ?               
                                <div className="content-part" key={`content${i}`}>      
                                    <h3>{contentPart.name}</h3>           
                                    <video controls aria-label="Video Player" preload="metadata" id="video" poster={contentPart.poster} className="video-player">	
                                        <source src={contentPart.video} type="video/mp4" />
                                        <p>
                                            Your browser doesn't support HTML5 video.
                                        </p>
                                    </video>             
                                </div>               
                            : contentPart.type === 'applet' ?
                                <div className="content-part iframe applet" key={`content${i}`}>
                                    {/* <div style={{padding:"100px"}}>
                                    <h3>KnowDL content to come </h3>
                                    </div> */}
                                    <iframe src={contentPart.link} title="StatCrunch Applet" ref={iframe}></iframe>
                                </div>
                                : contentPart.type === 'iframe' ?
                                
                                <div className="content-part iframe" key={`content${i}`} >
                                    <NarrativeContent link={contentPart.link}  key={`contentloader${props.currentItem}`}/>
                                    {/* <content-holder path={process.env.PUBLIC_URL + contentPart.link}>
                                    </content-holder> */}
                                    {/* <load-file src={process.env.PUBLIC_URL + contentPart.link} key={`contentloader${i}`}></load-file> */}
                                    {/* <iframe src={process.env.PUBLIC_URL + contentPart.link} title={contentPart.title} ref={iFrameRef}></iframe> */}
                                </div>
                            : contentPart.type === 'narrative' ?
                                <div className="content-part" key={`content${i}`}>
                                    <h3>{contentPart.name}</h3>           
                                    {contentPart.content.map((item, j) => {
                                        return(
                                            <p key={`para${j}`}>{item.text}</p>
                                        )
                                    })}
                                </div>
                            : contentPart.type === 'accordion' ?
                                <div className="content-part" key={`content${i}`}>
                                    {contentPart.content.map((item, j) => {
                                        return(
                                            <Accordion data={item} key={`accordion${j}`} />
                                        )
                                    })}
                                </div>
                            : contentPart.type === 'question' ?                            
                                contentPart.splitview ? 
                                    <div className="content-part full-width" key={`content${i}`}>
                                        <SplitPane split="horizontal" minSize={120} defaultSize={150}>
                                            <div className="question-stem" dangerouslySetInnerHTML={{ __html: contentPart.stem }} />
                                            
                                            <div>
                                                {typeof(contentPart.answer) === 'object' ?
                                                    <div className="answer" onClick={props.answerEntered}  dangerouslySetInnerHTML={{ __html: contentPart.answer[props.currentStep].step }} key={`content${i}`} />                                            
                                                :
                                                    <div className="answer single" onClick={props.answerEntered}  dangerouslySetInnerHTML={{ __html: contentPart.answer }} key={`content${i}`} />
                                                }                                        
                                                {props.answerCorrect && 
                                                    <div className="answer-status" ref={ref1}>
                                                        <div className="title">
                                                            That's correct
                                                        </div>
                                                    </div>
                                                }  
                                            </div>
                                        </SplitPane>                              
                                    </div>
                                :
                                    <div className="content-part" key={`content${i}`}>
                                        <div className="tdx-wrapper card">
                                            {contentPart.title && <h3>{contentPart.title}</h3> }
                                            <div className="question-stem" dangerouslySetInnerHTML={{ __html: contentPart.stem }} />
                                        </div>
                                        
                                        {contentPart.answerOptions ?
                                            <ul className="answer-options">
                                                {contentPart.answerOptions.map((element, j) => {
                                                    return(
                                                    <li key={`answer${props.currentItem}${j}`}>
                                                        <div className={`gr-radio ${props.selectedAnswer === j && props.answerCorrect ? 'selected' : ''}`}>
                                                            <input type={contentPart.multiple ? "checkbox":"radio"} name={`ansOption${props.currentItem}`} id={`option${props.currentItem}${j}`} onChange={() => props.selectAsnwer(j)} defaultChecked={props.selectedAnswer === j} disabled={props.answerCorrect && props.selectedAnswer !== j} />
                                                            <label htmlFor={`option${props.currentItem}${j}`}>{element.text}</label>
                                                            <span>
                                                                <svg focusable="false" className="icon-24 select-icon" aria-hidden="true">
                                                                    {contentPart.multiple ? 
                                                                    <path d="M4 4h16v16H4z"></path> :
                                                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#single-selected-24"></use> }
                                                                </svg>
                                                                <svg focusable="false" className="icon-24 correct-icon" aria-hidden="true">
                                                                    {contentPart.multiple ? 
                                                                    <path d="M4 4h16v16H4z"></path> :
                                                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#single-correct-24"></use> }
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </li>
                                                    )
                                                })}
                                            </ul>
                                        : 
                                            
                                            typeof(contentPart.answer) === 'object' ?
                                                <div className="answer" onClick={props.answerEntered}  dangerouslySetInnerHTML={{ __html: contentPart.answer[props.currentStep].step }} key={`content${i}`} />                                            
                                            :
                                                <div className="answer single" onClick={props.answerEntered}  dangerouslySetInnerHTML={{ __html: contentPart.answer }} key={`content${i}`} />
                                        }
                                        {props.answerCorrect && 
                                            <div className="answer-status" ref={ref}>
                                                <div className="title">
                                                    That's correct
                                                </div>
                                            </div>
                                        }
                                    </div>
                            : contentPart.type === 'matching' ? 
                            <div className="content-part" key={`content${i}`}>
                                        <div className="question-stem" dangerouslySetInnerHTML={{ __html: contentPart.stem }} />
                                        <div className="matching-container">
                                            <div className="matching-terms">
                                                {contentPart.terms.map((term,j) => {
                                                    return (
                                                        <button className="matching-term" key={"matchingTerm"+j} onClick={props.answerEntered}>{term}</button>
                                                    )
                                                })}
                                            </div>
                                            <div className="matching-buckets">
                                            {contentPart.buckets.map((bucket,k) => {
                                                    return (
                                                        <div className="matching-bucket" key={"matchingTerm"+k}><span className="bucket-label">{bucket.text}</span></div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                       
                                        {props.answerCorrect && 
                                            <div className="answer-status" ref={ref}>
                                                <div className="title">
                                                    That's correct
                                                </div>
                                            </div>
                                        }
                                    </div>
                            : contentPart.type === 'reading' ?
                                <div className="content-part" key={`content${i}`}>
                                    <h3>{contentPart.name}</h3>           
                                    {contentPart.content && contentPart.content.map((item, j) => {
                                        return(
                                            <div className="xln-content" dangerouslySetInnerHTML={{ __html: item.text }} key={`content${j}`} />
                                        )
                                    })}
                                </div>
                            : null}
                        </>
                    ) 
                })}
                    
            </section>

        
            <PlayerFooter 
                currentItem={props.currentItem} 
                action={props.data.action} 
                nextItem={props.nextItem}
                prevItem={props.prevItem}
                answerSelected={props.answerSelected}
                checkAnswer={props.checkAnswer}
                answerCorrect={props.answerCorrect}
                itemType={props.data.type}
                // navigationOpen={props.navigationOpen}
                subcat={props.data.subcat}
                disablePrev={props.currentItem === 0 || props.demo}
                disableNext={props.totalItems === 1 || props.demo}
            /> 
        </article>
    )
})

export default PlayerContent