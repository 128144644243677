import { useState } from "react"

const Accordion = (props) => {
    const [accordionOpen, setAccordionOpen] = useState(false);

    const toggleAccordion = () => {
        setAccordionOpen(!accordionOpen);
    }
    return(
        <div className={`accordion ${accordionOpen ? 'open' : ''}`}>
            <h4 role="button" onClick={toggleAccordion}>
                <svg focusable="false" className="icon-18" aria-hidden="true">
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#next-18"></use>
                </svg>
                {props.data.title}
            </h4>
            <div className="accordion-content" dangerouslySetInnerHTML={{ __html: props.data.content }} />
        </div>
    )
}

export default Accordion