import PlayerNavigation from "./PlayerNavigation";
import PlayerContent from "./PlayerContent";
import { useEffect, useRef, useState } from "react";
import PlayerHeader from "./PlayerHeader";
import PlayerContentScroll from "./PlayerContentScroll";

const Player = (props) => {
    const [answerSelected, setAnswerSelected] = useState(false);
    const [answerCorrect, setAnswerCorrect] = useState(false);
    const [navigationOpen, setNavigationOpen] = useState(false);
    const [mobileMenuOpen, setmobileMenuOpen] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState(false);
    const [modbilescoreOpen, setMobileScoreOpen] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    const [scrollTop, setScrollTop] = useState(0);
    let random = Math.random().toFixed(4);

    let scorePercentage = parseInt((props.data.overallscore / props.data.totalPoints) * 100);

    const selectedCardRef = useRef(null);
    const fbRef = useRef(null);
    const iFrameRef = useRef(null);

    const selectAsnwer = (selAns) => {
        setSelectedAnswer(selAns);
        setAnswerSelected(true);
    }

    const answerEntered = () => {
        setAnswerSelected(true);

    }

    const checkAnswer = () => {        
        setAnswerCorrect(true);
        setTimeout(() => {
            fbRef.current.scrollIntoView(true);
        }, 200);
    }

    const toggleNavigation = () => {
        setNavigationOpen(!navigationOpen);
        if (!navigationOpen) {
            setShowHeader(true)
        }
    }
    const goToItemFromNav = () => {
        // toggleNavigation()
        props.gotoItem()
        setNavigationOpen(false);
    }

    const nextItem = () => {
        setAnswerSelected(false);
        setAnswerCorrect(false);
        setSelectedAnswer(false);
        props.nextItem();
    }
    const prevItem = () => {
        console.log("go to prev")
        // setAnswerSelected(false);
        // setAnswerCorrect(false);
        // setSelectedAnswer(false);
        props.prevItem(props.currentItem - 1);
    }

    const toggleMobileMenu = () => {
        setmobileMenuOpen(!mobileMenuOpen);
    }
    const toggleMobileScores = () => {
        setMobileScoreOpen(!modbilescoreOpen);
    }
    const countItemTypes = () => {
        let count = {reading:0, video:0,activity:0,question:0, other:0, total: 0, totalPoints:0, totalTime:0}

        props.data.assignmentParts.forEach(part => {
            part.assignmentItems.forEach(item => {
                count.total = count.total + 1
                count.totalPoints = count.totalPoints + item.totalPoints
                count.totalTime = count.totalTime + +item.time //in seconds
                if (item.type === 'reading') {
                    count.reading = count.reading + 1
                    
                } else if (item.type === 'video') {
                    count.video = count.video + 1
                } else if (item.type === 'activity') {
                    count.activity = count.activity + 1
                } else if (item.type === 'question') {
                    count.question = count.question + 1
                } else {
                    count.other = count.other + 1
                }
            })
        })

        return count
    }

    useEffect(() => {
        setTimeout(() => {
            if (selectedCardRef.current) {                
                document.getElementById('card-selected').scrollIntoView(true);
            }
        }, 300)
        localStorage.setItem('currentItem', props.currentItem);
    });
    useEffect(() => {
        var prevScrollpos = window.pageYOffset;
        
        function onScroll() {
            var currentScrollPos = window.pageYOffset;
            // var st = window.scrollTop;
            if (prevScrollpos <= currentScrollPos ){
                // downscroll code
                // console.log("hide header")
                setShowHeader(false)
            } else {
                // upscroll code
                setShowHeader(true)
            } // else was horizontal scroll
            prevScrollpos = currentScrollPos; // For Mobile or negative scrolling
        //    console.log(currentScrollPos, prevScrollpos)
          }
      
          window.addEventListener("scroll", onScroll);
          return () => window.removeEventListener("scroll", onScroll);
        }, []);
    // useEffect(() => {
    //      var lastScrollTop = 0; 
    //      let iframe = iFrameRef.current
    //      console.log(iFrameRef.current)
    //     function checkScroll() {
    //           console.log("check scroll" + st)
    //         var st = iframe.contentWindow.document.documentElement.scrollTop;
    //         if (st > lastScrollTop) {
    //             // downscroll code
    //             // console.log("hide header")
    //             setShowHeader(false)
    //         } else if (st < lastScrollTop) {
    //             // upscroll code
    //             setShowHeader(true)
    //         } // else was horizontal scroll
    //         lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    //     //    console.log(lastScrollTop, st)
    //     }
    //     if (iframe) {
    //         iframe.contentWindow.addEventListener("scroll", checkScroll);
    //     }
        

    //     return () => {
    //         if (iframe !== null){
    //             iframe.contentWindow.removeEventListener("scroll",checkScroll)
    //         }
    //     }
    // },[props.currentItem])

    return(        
        <>
        <PlayerHeader toggleMobileMenu={toggleMobileMenu} mobileMenuOpen={mobileMenuOpen} 
            show={showHeader}
            data={props.data} closePlayer={props.closePlayer}/>
            
            <main className={`player-main ${showHeader ? "show-header" : "hide-header"}`}>
                <PlayerNavigation 
                    data={props.data} 
                    currentItem={props.currentItem} 
                    gotoItem={props.gotoItem}
                    itemsCompleted={props.itemsCompleted}
                    totalItems={props.data.totalItems}
                    toggleNavigation={toggleNavigation}
                    navigationOpen={navigationOpen}
                    mobileMenuOpen={mobileMenuOpen}
                    toggleMobileMenu={toggleMobileMenu}
                    ref={selectedCardRef}
                />
                {props.scroll ? 
                <PlayerContentScroll 
                    data={props.itemsList}
                    items={props.itemsList} 
                    currentItem={props.currentItem} 
                    nextItem={nextItem} 
                    prevItem={prevItem}
                    answerSelected={answerSelected}
                    selectAsnwer={selectAsnwer}
                    answerCorrect={answerCorrect}
                    checkAnswer={checkAnswer}
                    selectedAnswer={selectedAnswer}
                    // navigationOpen={navigationOpen}
                    answerEntered={answerEntered}
                    currentStep={props.currentStep}
                    totalSteps={props.totalSteps}
                    totalItems={props.data.totalItems}
                    ref={{ref1:fbRef,iFrameRef:iFrameRef}}
                    demo={props.data.demo}
                    id={random}
                    gotoItem={props.gotoItem}
                /> : 
                <PlayerContent 
                    data={props.itemsList[props.currentItem]} 
                    currentItem={props.currentItem} 
                    nextItem={nextItem} 
                    prevItem={prevItem}
                    answerSelected={answerSelected}
                    selectAsnwer={selectAsnwer}
                    answerCorrect={answerCorrect}
                    checkAnswer={checkAnswer}
                    selectedAnswer={selectedAnswer}
                    // navigationOpen={navigationOpen}
                    answerEntered={answerEntered}
                    currentStep={props.currentStep}
                    totalSteps={props.totalSteps}
                    totalItems={props.data.totalItems}
                    ref={{ref1:fbRef,iFrameRef:iFrameRef}}
                    demo={props.data.demo}
                    id={random}
                    
                /> }
            </main>
        </>  
    )
}

export default Player