/** @format */

import { useEffect, useState } from 'react';
import Header from './shared/Header';
import OverviewPage from './assignmentOverview/OverviewPage';
import Footer from './shared/Footer';
import Player from './assignmentPlayer/Player';
// import TopicSelector from './TopicSelector';
// import assignmentDataCalculus from './assets/data/calculus.json';
// import assignmentDataStats from './assets/data/stats.json';

import lesson0 from "./assets/data/lesson10-0.json";
import lesson2 from "./assets/data/lesson10-2.json";
import { useInView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router-dom';

const Wrapper = (props) => {
	const [data, setData] = useState(false);
	const [itemOpen, setItemOpen] = useState(props.type && props.type === 'assignment' ? true : false);
	const [currentItem, setCurrentItem] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [totalSteps, setTotalSteps] = useState(1);
	const [itemsList, setItemsList] = useState([]);
	const [grouped, setGrouped] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();
	const search = location.search;

	useEffect(() => {
		const assignment = new URLSearchParams(search).get('content');
		const grouped = new URLSearchParams(search).get('grouped');

		if (assignment) {
			if (assignment === "lesson10-0") {
				setSelectedTopic(lesson0)
			} else  {
				setSelectedTopic(lesson2)
			} 
		} else {
			setSelectedTopic(lesson2)
		}
		if (grouped && grouped === 'true') {
			setGrouped(true)
		}
	},[])

	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0,
	});

	const setSelectedTopic = (topic) => {
		let tempList = {...topic};
        setCurrentItem(0);
		// if (topic === 'calculus') {
		// 	tempList = assignmentDataCalculus;
		// 	setCurrentItem(0);
		// } else if (topic === 'calculus') {
		// 	tempList = assignmentDataStats;
		// 	setCurrentItem(0);
		// } else {
		// 	tempList = assignmentDataap;
		// 	setCurrentItem(0);
		// }
		// console.log(tempList.assignmentParts)
		let tempList2 = [];
		tempList.assignmentParts.forEach((element) => {
			element.assignmentItems.forEach((item, i) => {
				tempList2.push(item);
			});
		});
		tempList.totalItems = countItemTypes(tempList).total
		tempList.totalPoints = countItemTypes(tempList).totalPoints
		tempList.totalTime = countItemTypes(tempList).totalTime
		setData(tempList);
		setItemsList(tempList2);
	};

	const openPlayer = () => {
		setItemOpen(true);
		navigate(`/assignment${search}`)
	};

	const closePlayer = () => {
		setItemOpen(false);
		navigate(`/assignmentOverview${search}`)
	};
	// const navigateToAssgn = () => {
    //     navigate(`/assignment${search}`)
    // }

	//Navigate to next item - Called from the next button
	const nextItem = () => {
		/* Check how many steps */
		if (totalSteps > 1 && currentStep < totalSteps - 1) {
			setCurrentStep(currentStep + 1);
		} else {
			/* Set current item as complete */
			let itemPoints = itemsList[currentItem].totalPoints
				? itemsList[currentItem].totalPoints
				: 0;
			let totalScore = data.overallscore + itemPoints;

			var tempList = [...itemsList];
			if (tempList[currentItem].status !== 'completed') {
				tempList[currentItem].pointsEarned = itemPoints;
				tempList[currentItem].status = 'completed';

				/* Update status of items in the full stack */
				let fullData = data;
				fullData.assignmentParts.forEach((element) => {
					element.assignmentItems.forEach((e) => {
						if (e.itemid === itemsList[currentItem].itemId) {
							e.pointsEarned = itemPoints;
							e.status = 'completed';
						}
					});
				});

				/* Update total score and items completed */
				fullData.itemsCompleted = data.itemsCompleted + 1;
				fullData.overallscore = totalScore;

				setData(fullData);
				setItemsList(tempList);

				/* Check if the locked item needs to be unlocked */
				// checkToUnlock(7);
			}

			if (currentItem < tempList.length - 1) {
				/* Check if question has multiple parts ---*/
				if (
					tempList[currentItem + 1].type === 'question' &&
					typeof tempList[currentItem + 1].content[0].answer === 'object'
				) {
					setTotalSteps(tempList[currentItem + 1].content[0].answer.length);
					setCurrentStep(0);
				}

				/* Check if item is still locked ---*/
				if (tempList[currentItem + 1].locked) {
					setCurrentItem(currentItem + 2);
				} else {
					setCurrentItem(currentItem + 1);
				}
			} else {
				setItemOpen(false);
			}

			document.getElementById('assignmentContent').scrollTo(0, 0);
		}
	};

	const prevItem = (num) => {
		gotoItem(num)
	}
	// Checks if Locked item can be unlocked & unlocks it
	const checkToUnlock = (itemIndex) => {
		let tempList = [...itemsList];
		let prereqItems = tempList.filter(
			(a) => a.prereqfor === tempList[itemIndex].itemid
		);
		// console.log(prereqItems);
		let lockedState = false;
		if (tempList[itemIndex].locked) {
			for (var i = 0; i < prereqItems.length; i++) {
				if (prereqItems[i].status !== 'completed') {
					lockedState = true;
				}
			}
			tempList[itemIndex].locked = lockedState;
		}
		let fullData = {...data};
		fullData.assignmentParts.forEach((element) => {
			element.assignmentItems.forEach((e) => {
				if (e.itemid === itemsList[itemIndex].itemid && lockedState) {
					e.locked = true;
				}
			});
		});
		

		setData(fullData);
		setItemsList(tempList);
	};

	//Navigate to a specfic item. Called from cards.
	const gotoItem = (itemNum) => {
		if (!itemOpen) {
			setItemOpen(true);
		}
		setCurrentItem(itemNum);

		/* Check if the locked item needs to be unlocked */
		// checkToUnlock(7);
	};
	const countItemTypes = (source) => {
        let count = {reading:0, video:0,activity:0,question:0, other:0, total: 0, totalPoints:0, totalTime:0}

        source.assignmentParts.forEach(part => {
            part.assignmentItems.forEach(item => {
                count.total = count.total + 1
                count.totalPoints = count.totalPoints + item.totalPoints
                count.totalTime = count.totalTime + +item.time //in seconds
                if (item.type === 'reading') {
                    count.reading = count.reading + 1
                    
                } else if (item.type === 'video') {
                    count.video = count.video + 1
                } else if (item.type === 'activity') {
                    count.activity = count.activity + 1
                } else if (item.type === 'question') {
                    count.question = count.question + 1
                } else {
                    count.other = count.other + 1
                }
            })
        })

        return count
    }
	// useEffect(() => {
    //     setSelectedTopic('aandp');
	// 	// if (location.pathname.indexOf('a&p') > -1) {
	// 	// 	setSelectedTopic('aandp');
	// 	// }
	// },[]);

	if (data) {return (
		<>
			
			{itemOpen ? (
				<Player
					closePlayer={closePlayer}
					data={data}
					currentItem={currentItem}
					nextItem={nextItem}
					prevItem={prevItem}
					itemsList={itemsList}
					gotoItem={gotoItem}
					itemsCompleted={data.itemsCompleted}
					totalItems={data.totalItems}
					currentStep={currentStep}
					totalSteps={totalSteps}
					scroll={props.scroll}
				/>
			) : (
				<>
				<Header
					itemOpen={itemOpen}
					ref={ref}
					page={itemOpen ? "player" : "overview"}
					title={data.chapter}
				/>
					<OverviewPage
						data={data}
						setData={setData}
						openPlayer={openPlayer}
						gotoItem={gotoItem}
						inView={inView}
						itemsList={itemsList}
						currentItem={currentItem}
						grouped={grouped}
					/>
					{/* <Footer /> */}
				</>
			)}
		</>
	) } else {
        return (
            <div>loading...</div>
        )
    }
}

export default Wrapper;
